<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <v-spacer></v-spacer>
        <span class="text-h5">{{this.form.id ? 'Edição' : 'Cadastro'}} de Usuário</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="center" justify="center" style="height: 380px;">
        <FormInput
          label="Nome"
          v-model="form.name"
        />
        <!-- <FormInputMask
          label="CPF"
          placeholder="000.000.000-00"
          :mask="['###.###.###-##']"
          v-model="form.cpf"
        /> -->
        <FormInput
          label="E-mail"
          v-model="form.email"
        />
        <!-- <FormInputMask
          label="Telefone"
          placeholder="+55 (99) 99999-9999"
          :mask="['+## (##) ####-####', '+## (##) #####-####']"
          v-model="form.phone"
        /> -->
        <VuePhoneNumberInput
          v-model="form.phone"
          @update="phone = $event"
          :translations="{
            countrySelectorLabel: 'Código do país',
            countrySelectorError: 'Código do país',
            phoneNumberLabel: 'Telefone',
            example: ''
          }"
          no-example
          class="mt-2"
        />
        <FormInput
          label="Senha (mínimo 8 caracteres, use letras e números)"
          type="password"
          v-model="form.password"
        />
        <FormInput
          label="Confirmar Senha"
          type="password"
          v-model="form.password_confirm"
        />
        <v-select
          class="mt-2"
          v-model="form.tipo"
          :items="[{value: 'admin', text: 'Administrador'}, {value: 'cliente', text: 'Cliente'}]"
          label="Tipo"
          hide-details
          outlined
          dense
        />
        <v-select
          class="mt-2 mb-12"
          v-model="form.status"
          :items="[{value: 'ativo', text: 'Ativo'}, {value: 'inativo', text: 'Inativo'}]"
          label="Status"
          outlined
          dense
        />
      </v-card-text>
      <v-card-actions>
          <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
          <v-spacer />
          <v-btn color="success" @click="salvar" :loading="loading">
            <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormInput from '@/components/FormInput';
// import FormInputMask from '@/components/FormInputMask';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'UserForm',

  components: {
    FormInput,
    // FormInputMask,
    VuePhoneNumberInput,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: Object
    },
  },

  data: () => ({
    loading: false,
    form: {
      name: '',
      cpf: '',
      email: '',
      phone: '',
      tipo: '',
      status: 'ativo',
      password: '',
      password_confirm: '',
    },
    phone: '',
  }),

  mounted() {
    this.form = this.formData;
  },

  watch: {
    formData(value) {
      this.form = value ? JSON.parse(JSON.stringify(value)) : {};
    }
  },

  methods: {
    salvar() {
      if (this.form.password != this.form.password_confirm) {
        this.$toast.error('Senha não confere');
        return;
      }

      const id = this.form.id;

      this.form.phone = this.phone.formatInternational || '';

      if (id) {
        this.loading = true;
        this.$http.put(`users/${id}`, this.form).then(resp => {
          if (resp.data.type === 'warning') {
            this.$toast.error(resp.data.msg);
            return;
          }

          this.$emit('onCadastro', resp.data.data);
          this.$toast.success('Atualizado com sucesso!');
        }).catch(() => {
          this.$toast.error('Não foi possivel concluir a operação');
        }).finally(() => (this.loading = false));
        return;
      }

      this.loading = true;
      this.$http.post('users', this.form).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.$emit('onCadastro', resp.data.data);
        this.$toast.success('Cadastrado com sucesso!');
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}
</style>
